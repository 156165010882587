body {
  overflow-y: auto;
  min-height: 100vh;
}

#__next {
  height: 100%;
}

.fade-enter .fading {
  opacity: 0;
}
.fade-enter-active .fading {
  opacity: 1;
}
.fade-exit .fading {
  opacity: 1;
}
.fade-exit-active .fading {
  opacity: 0;
}
.fade-enter-active .fading,
.fade-exit-active .fading {
  transition:
    opacity 500ms,
    transform 500ms;
}

.workflow-icon {
  min-width: 24px !important;
  text-align: right;
  margin-right: 12px;
}

.workflow-icon > svg {
  display: block;
  margin: auto;
}

/*
Targets the hotjar elements like the feedback button
so it shows a border when focused
*/
[class*='_hj']:focus {
  border: 1px solid black !important;
}

[class*='_MinimizedWidgetMiddle__container'] {
  top: 50% !important;
}

/**
  * Targets the close button of the cookie consent banner
  * so it shows a border when focused
*/
[class$='__closeButton']:focus {
  border: 1px solid #3682f5 !important;
}

.pointer {
  cursor: pointer;
}

.file-content {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: auto;
}

.display-none {
  display: none;
}

.hidden {
  visibility: hidden;
}

.bold {
  font-weight: bold;
}

.italic {
  font-style: italic;
}

/* used by JupyterNotebookPreview */
.input-outer > p {
  color: #3746bc;
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}
